import { FC, SyntheticEvent, useState } from "react";
import {
  FlexboxGrid,
  Container,
  ButtonToolbar,
  Button,
  Table,
} from "rsuite";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";
import "./mission.css";
import MissionModal from '../../components/MissionModal';
import { gref } from '../../common/constants';

const { Column, HeaderCell, Cell } = Table;

type MissionProps = {};

const Mission: FC<MissionProps> = () => {
  const data = dataKeys;
  const [isOpen, setOpen] = useState<boolean>(true);

  const close = () => {
    setOpen(false);
  };

  const open = () => {
    setOpen(true);
  };

  const handleAddNewMission = (e: SyntheticEvent) => {
    open();
  };

  return (
    <div>
      <MissionModal isOpen={isOpen} close={close} />
      <Container style={{ margin: "20px" }}>
        <h3 style={{ textAlign: "center" }}>Uppdrag</h3>
        <FlexboxGrid>
          <FlexboxGridItem colspan={12}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <a className="pl-25" href={gref}>
                Pagaende
              </a>
              <p className="pl-25">Avbrutna</p>
            </div>
          </FlexboxGridItem>
          <FlexboxGridItem colspan={12}>
            <ButtonToolbar className="button-group">
              <Button appearance="primary" onClick={handleAddNewMission}>
                + Nytt uppdrag
              </Button>
              <Button appearance="primary">+ Ny omplacering</Button>
            </ButtonToolbar>
          </FlexboxGridItem>
        </FlexboxGrid>
        <div style={{ marginBottom: "40px", display: "flex" }}></div>
        <div>
          <Table
            style={{
              padding: "10px 25px 10px 25px",
            }}
            height={400}
            data={data}
            onRowClick={(data: any) => {
              console.log(data);
            }}
          >
            <Column width={40} align="left">
              <HeaderCell classPrefix="column-header">
                <span id="test">Id</span>
              </HeaderCell>
              <Cell dataKey="Id" />
            </Column>
            <Column width={210} align="left">
              <HeaderCell classPrefix="column-header">Staus</HeaderCell>
              <Cell className="cell-value" dataKey="Staus" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell classPrefix="column-header">Kommun</HeaderCell>
              <Cell className="cell-value" dataKey="Kommun" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell classPrefix="column-header">Inkommen</HeaderCell>
              <Cell className="cell-value" dataKey="Inkommen" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell classPrefix="column-header">Ansvaring</HeaderCell>
              <Cell className="cell-value" dataKey="Ansvaring" />
            </Column>
            <Column width={150} align="left">
              <HeaderCell classPrefix="column-header">Sokomrade</HeaderCell>
              <Cell
                style={{ width: "100% !important" }}
                className="cell-value"
                dataKey="Sokomrade"
              />
            </Column>
          </Table>
        </div>
      </Container>
    </div>
  );
};

export default Mission;

const dataKeys: Array<any> = [
  {
    Id: "1",
    Staus: "staus",
    Kommun: "Kommun",
    Inkommen: "Inkommen",
    Ansvaring: "Ansvaring",
    Sokomrade: "Sokomrade",
  },
  //   {
  //     Id: "2",
  //     Staus: "staus",
  //     Kommun: "Kommun",
  //     Inkommen: "Inkommen",
  //     Ansvaring: "Ansvaring",
  //     Sokomrade: "Sokomrade",
  //   },
];

import { FC, useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  ButtonGroup,
  ControlLabel,
  DatePicker,
  Divider,
  FlexboxGrid,
  Form,
  FormGroup,
  Icon,
  Input,
  InputGroup,
  InputPicker,
  Modal,
  SelectPicker,
  TreePicker,
} from "rsuite";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";
import Minus from "../assets/minus.svg";
import Plus from "../assets/plus.svg";
import { httpRequest } from "../common/helpers";
import {
  ADD_MISSION,
  GET_ALL_MUNICIPALITIES,
  GET_ALL_REGIONS,
  GET_ALL_USERS_RECRUITERS,
} from "../common/constants";
import AddContactModal from "./AddContactModal";
import Placed from "../pages/Mission/Placed";
import { Gender } from "../pages/Mission/missionTypes";

type Municipalities = {
  code: string;
  name: string;
};

type Region = {
  name: string;
  countyShortName: string;
  countyName: string;
  municipalities: Array<Municipalities>;
};

export enum Role {
  ADMIN = "admin",
  RECRUITER = "recruiter",
  FAMILYHOMECONSULTANT = "familyhomeconsultant",
  TREATMENTASSISTANT = "treatmentassistant",
}

type User = {
  role: Role;
  name: string;
};

type DataItemType = {
  value: string; // property value is the value of valueKey
  label: React.ReactNode; // property value is the vaue of labelKey
  children?: Array<DataItemType>; // property value is the value of childrenKey
  groupBy?: string;
};

export interface MultipleInput<T> {
  index: number;
  value: T;
}

type Props = {
  isOpen: boolean;
  close: () => void;
};

const MissionModal: FC<Props> = ({ isOpen, close }) => {
  const [income, setIncome] = useState<Date>(new Date());
  const [respInv, setRespInv] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [locForm, setLocForm] = useState<string>("");
  const [legalSupp, setLegalSupp] = useState<string>("");
  const [famHomeSecretary, setFamHomeSecretary] = useState<string>("");
  const [childAdm, setChildAdm] = useState<string>("");
  const [criteria, setCriteria] = useState<string>("");
  const [numberToBePlaced, setNumberToBePlaced] = useState<number>(1);
  const [name, setName] = useState<MultipleInput<string>[]>([]);
  const [yearOfBirth, setYearOfBirth] = useState<MultipleInput<number>[]>([]);
  const [gender, setGender] = useState<MultipleInput<Gender>[]>([]);
  const [description, setDescription] = useState<MultipleInput<string>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenNestedModal, setOpenNestedModal] = useState<boolean>(false);
  const [dataMunicipalities, setDataMunicipalities] = useState<string[]>([]);
  const [municipality, setMunicipality] = useState<string>("");
  const [region, setRegion] = useState<DataItemType[]>([]);
  const [recruiters, setRecruiters] = useState<DataItemType[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<any[]>([]);

  useEffect(() => {
    const data = async () => {
      const res: Municipalities[] = await httpRequest.get(
        GET_ALL_MUNICIPALITIES
      );
      const format = res.map((item) => item.name);

      const res2: Region[] = await httpRequest.get(GET_ALL_REGIONS);
      const format2 = res2.map((item) => {
        const mnsplts = item.municipalities.map((elem) => ({
          label: elem.name,
          value: elem.name,
        }));

        return {
          label: item.name,
          value: item.name,
          children: mnsplts,
        };
      });

      const res3: User[] = await httpRequest.get(GET_ALL_USERS_RECRUITERS);
      const format3 = res3.map((item) => ({
        label: item.name,
        value: item.name,
        role: Role.RECRUITER,
      }));

      setDataMunicipalities(format);
      setRegion(format2);
      setRecruiters(format3);
    };
    data();
  }, []);

  // console.log('name', name);
  // console.log('year', yearOfBirth);
  // console.log('descr', description);
  // console.log('sex', gender);
  
  const closeNestedModal = () => {
    setOpenNestedModal(false);
  };

  const openNestedModal = () => {
    setOpenNestedModal(true);
  };

  const onMinus = () => {
    if (numberToBePlaced === 1) {
      return;
    }
    setNumberToBePlaced(numberToBePlaced - 1);
    const tempName = [...name];
    tempName.pop();

    const tempDescr = [...description];
    tempDescr.pop();

    const tempYear = [...yearOfBirth];
    tempYear.pop();

    const tempGender = [...gender];
    tempGender.pop();

    setName(tempName);
    setDescription(tempDescr);
    setYearOfBirth(tempYear);
    setGender(tempGender);
  };

  const onSelectLocation = ({ label }: any) => {
    for (let i = 0; i < region.length; i++) {
      const item = region[i];

      if (item.label !== label && item.children) {
        for (let j = 0; j < item.children.length; j++) {
          let elem = item.children[j];

          if (elem.label === label) {
            return setSelectedLocation([
              ...selectedLocation,
              { regionName: item.label, municipality: elem.label },
            ]);
          }
        }
      } else if (item.label === label) {
        return setSelectedLocation([
          ...selectedLocation,
          { regionName: item.label },
        ]);
      }
    }
  };

  const handleSubmit = () => {
    // setLoading(true);

    const data = {
      income: income.toISOString(),
      responsibleRecruiter: respInv,
      source,
      assignmentForm: locForm,
      legalClause: legalSupp,
      municipality: "municipality",
      contactName: famHomeSecretary ? famHomeSecretary : childAdm,
      familySecretary: famHomeSecretary ? "familySecretary" : "",
      childAdministrator: childAdm ? "childAdministrator" : "",
      criteria,
      placedName: name,
      yearOfBirth: yearOfBirth,
      gender,
      description,
      searchLocation: undefined,
      searchRadius: undefined,
    };

    if (!data.income) return alert(`income* is required`);
    if (!data.responsibleRecruiter ) return alert(`responsibleRecruiter* is required`);
    if (!data.source) return alert(`source* is required`);
    if (!data.assignmentForm) return alert(`assignmentForm* is required`);
    if (!data.legalClause) return alert(`legalClause* is required`);
    if (!data.municipality) return alert(`municipality* is required`);
    if (!data.contactName) return alert(`contactName* is required`);
    if (!data.familySecretary && !data.childAdministrator) return alert(`familySecretary* or childAdministrator is required`);
    if (!data.criteria) return alert(`criteria* is required`);

      const p = data.placedName.map( elem => !!elem.value);
      if (p.includes(false) || p.length === 0) return alert(`name* is required`);
      
      const y = data.yearOfBirth.map( elem => !!elem.value);
      console.log('y', data.yearOfBirth);
      console.log('y2', y);
      
      
      if (y.includes(false) || y.length === 0) return alert(`yearOfBirth* is required`);

      const g = data.gender.map( elem => !!elem.value) 
      if (g.includes(false) || g.length === 0) return alert(`gender* is required`);

      const d = data.description.map( elem => !!elem.value) 
      if (d.includes(false) || d.length === 0) return alert(`description* is required`);
  
    // console.log("data", data);

    httpRequest.post(ADD_MISSION, data);

    setTimeout(() => {
      setLoading(false);
      // close();
    }, 2000);
  };

  // console.log('selected', val);

  return (
    <>
      <AddContactModal
        isOpen={isOpenNestedModal}
        close={closeNestedModal}
        setFamHomeSecretary={setFamHomeSecretary}
        setChildAdm={setChildAdm}
      />
      <Modal size="lg" show={isOpen} onHide={close}>
        <Modal.Header>
          <Modal.Title
            style={{
              textAlign: "center",
              fontSize: "25px",
              fontWeight: "normal",
            }}
          >
            Ny placering
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0 25px" }}>
          <h6>Placerings ID: ----</h6>
          <Form>
            <FlexboxGrid>
              <FlexboxGridItem className="flex-box-grid-item-modal">
                <FormGroup>
                  <ControlLabel>Inkom</ControlLabel>
                  <DatePicker
                    className="mr-40"
                    onChange={(val: Date, e) => setIncome(val)}
                    defaultValue={new Date()}
                    style={{ width: 250 }}
                    value={income}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Ansvarig placerare:</ControlLabel>
                  <SelectPicker
                    className="mr-40"
                    placeholder="Välj"
                    data={recruiters}
                    style={{ width: 224 }}
                    onChange={(value: string, e) => setRespInv(value)}
                  />
                </FormGroup>
                <FormGroup>
                  <ControlLabel>Källa:</ControlLabel>
                  <InputPicker
                    className="mr-40"
                    placeholder="Välj"
                    onChange={(value: string, e) => setSource(value)}
                    data={sourceData}
                    style={{ width: 224 }}
                  />
                </FormGroup>
              </FlexboxGridItem>
            </FlexboxGrid>
            <FlexboxGridItem className="flex-box-grid-item-modal">
              <FormGroup>
                <ControlLabel>Placeringsform:</ControlLabel>
                <InputPicker
                  className="mr-40"
                  placeholder="Välj"
                  onChange={(value: string, e) => setLocForm(value)}
                  data={assignmentFormData}
                  style={{ width: 250 }}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Lagstöd</ControlLabel>
                <InputPicker
                  className="mr-40"
                  placeholder="Välj"
                  onChange={(value: string, e) => setLegalSupp(value)}
                  data={legalSupportData}
                  style={{ width: 224 }}
                />
              </FormGroup>
            </FlexboxGridItem>
            <Divider className="divider" />

            <h6>Kund</h6>
            <FlexboxGridItem className="flex-box-grid-item-modal">
              <FormGroup className="mr-40">
                <ControlLabel>Kommun:</ControlLabel>
                <InputGroup className="input-group">
                  <AutoComplete data={dataMunicipalities} value={municipality} onChange={setMunicipality} />
                  <InputGroup.Button>
                    <Icon icon="search" />
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
              <ButtonGroup className="mr-40" style={{ marginTop: "24px" }}>
                <Button appearance="primary" onClick={openNestedModal}>
                  Lägg till kontakt
                </Button>
              </ButtonGroup>
              <FormGroup className="first">
                <ControlLabel className="underline">
                  Familjehemssekreterare:
                </ControlLabel>
                <Input value={famHomeSecretary} className="input-role" />
                <ControlLabel className="underline">
                  Barnhandläggare
                </ControlLabel>
                <Input value={childAdm} className="input-role" />
              </FormGroup>
            </FlexboxGridItem>
            <Divider />
            <h6>Sökkriterier</h6>
            <FlexboxGridItem className="flex-box-grid-item-modal">
              <FormGroup className="mr-40">
                <ControlLabel>Sökområde:</ControlLabel>
                <TreePicker
                  placeholder="Välj"
                  data={region}
                  onSelect={onSelectLocation}
                  style={{ width: 250 }}
                />
                {selectedLocation.map((item) => {
                  return (
                    <ControlLabel
                      style={{ width: "250px", color: "#272c367d", opacity: 1 }}
                    >
                      {item.regionName}
                      {item.municipality && ", " + item.municipality}
                      <Button
                        type="button"
                        className="rs-modal-header-close"
                        style={{
                          padding: "4px 4px 4px 5px",
                          width: 30,
                          height: 30,
                        }}
                        aria-label="Close"
                        onClick={(e) => {
                          e.stopPropagation();

                          const deleteItem = selectedLocation.filter((elem) => {
                            if (item.municipality) {
                              return elem.municipality !== item.municipality;
                            } else if (!elem.municipality) {
                              return elem.regionName !== item.regionName;
                            }
                            return true;
                          });
                          setSelectedLocation(deleteItem);
                        }}
                      >
                        <div style={{ width: 20, height: 20 }} aria-hidden>
                          x
                        </div>
                      </Button>
                    </ControlLabel>
                  );
                })}
              </FormGroup>
              <FormGroup>
                <ControlLabel>Kriterier:</ControlLabel>
                <Input
                  style={{ width: "600px" }}
                  placeholder="Textområde"
                  value={criteria}
                  onChange={(val: string) => {
                    setCriteria(val);
                  }}
                  componentClass="textarea"
                  rows={3}
                />
              </FormGroup>
            </FlexboxGridItem>
            <Divider className="mt-20" />
            <h6>Placerade</h6>
            <FlexboxGridItem
              className="flex-box-grid-item-modal"
              style={{ marginBottom: "15px" }}
            >
              <ControlLabel className="input-number">
                Antal som ska placeras:
              </ControlLabel>
              <Button
                appearance="subtle"
                onClick={onMinus}
              >
                <img src={Minus} alt="React Logo" width="15" height="15" />
              </Button>
              <Input
                style={{ paddingRight: "10px", width: "41px" }}
                value={String(numberToBePlaced)}
              />
              <Button
                appearance="subtle"
                onClick={() => setNumberToBePlaced(numberToBePlaced + 1)}
              >
                <img src={Plus} alt="React Logo" width="15" height="15" />
              </Button>
            </FlexboxGridItem>
            <Placed
              name={name}
              gender={gender}
              setName={setName}
              setGender={setGender}
              yearOfBirth={yearOfBirth}
              description={description}
              setYearOfBirth={setYearOfBirth}
              setDescription={setDescription}
              numberToBePlaced={numberToBePlaced}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <FlexboxGrid style={{ width: "100%" }}>
            <FlexboxGrid.Item colspan={12} className="btn-ok">
              <Button
                className="btn-group-modal"
                onClick={close}
                appearance="subtle"
              >
                Avbryt
              </Button>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={12} className="btn-cancel">
              <Button
                className="btn-group-modal"
                onClick={handleSubmit}
                appearance="primary"
                loading={loading}
              >
                Spara
              </Button>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MissionModal;

const sourceData = ["Direct", "SSIL", "Alla familjehem", "Övrigt"].map(
  (item) => ({
    label: item,
    value: item,
  })
);

const assignmentFormData = [
  "Fast placering",
  "Jour placering",
  "Behandlingsfamiljs placering",
].map((item) => ({
  label: item,
  value: item,
}));

const legalSupportData = ["SOL", "LVU"].map((item) => ({
  label: item,
  value: item,
}));

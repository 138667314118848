import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, Icon, Nav, Navbar, Panel, Placeholder, Steps } from 'rsuite';
import { ROUTES } from '../../routes';

interface RegisterFamilyProps {
  
}

const RegisterFamily: React.FC<RegisterFamilyProps> = () => {
  const [step, setStep] = useState<number>(0);
  const onChange = (nextStep: number) => {
    setStep(nextStep < 0 ? 0 : nextStep > 3 ? 3 : nextStep);
  };

  const onNext = () => onChange(step + 1);
  const onPrevious = () => onChange(step - 1);
  return (
    <Fragment>
      <NavBarInstance/>
      <div style={{ padding: 20 }}>
        <Panel shaded bordered>
          <Steps current={step}>
            <Steps.Item title="Start" />
            <Steps.Item title="Kontaktuppgifter" />
            <Steps.Item title="Familj & Boende" />
            <Steps.Item title="Erfarenhet" />
          </Steps>
          <hr />
          <Panel header={`Step: ${step + 1}`}>
            <Placeholder.Paragraph />
          </Panel>
          <hr />
          <ButtonGroup>
            <Button onClick={onPrevious} disabled={step === 0}>
              Previous
            </Button>
            <Button onClick={onNext} disabled={step === 3}>
              Next
            </Button>
          </ButtonGroup>
        </Panel>
      </div>
    </Fragment>
  )
};

export default RegisterFamily;


const NavBarInstance = (props: any) => {
  const history = useHistory();
  return (
    <Navbar {...props}>
      <Navbar.Header>
        <h2 style={{ margin: '0 20px' }}>
          FAWO
        </h2>
      </Navbar.Header>
      <Navbar.Body>

        <Nav pullRight>
          <Dropdown icon={<Icon icon="user" />} title="First name">
            <Dropdown.Item eventKey="1">Profile</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={() => history.push(ROUTES.LOGIN)}>Exit</Dropdown.Item>
          </Dropdown>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};




export const httpRequest = {
   post: async (url = '', data = {}) => {
        // Default options are marked with *
        const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer',//'client', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        
        return await response.json(); // parses JSON response into native JavaScript objects
    },

    get: async (url = '', data = {}) => {
        const response = await fetch(url);
        return await response.json();
    }
}

export const currentYear = new Date().getFullYear();
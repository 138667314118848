const baseUrl = process.env.REACT_APP_BASE_URL;

export const ADD_MISSION = baseUrl + '/missions/createma';

export const GET_ALL_MUNICIPALITIES = baseUrl + '/municipalities';

export const GET_ALL_CONTACTS = baseUrl + '/contacts';

export const GET_ALL_REGIONS = baseUrl + '/regions';

export const GET_ALL_USERS_RECRUITERS = baseUrl + '/users?role=recruiter';

export const gref = "https://www.google.com/webhp?hl=ru&sa=X&ved=0ahUKEwjA8Zv9_OvwAhWytYsKHVftCOcQPAgL";
import React from 'react';

interface RegisterProps {
  
}

const Register: React.FC<RegisterProps> = () => {
  return (
    <div>Register it's works</div>
  )
};

export default Register;
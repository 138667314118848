import React from 'react';

interface HomeProps {
  
}

const Home: React.FC<HomeProps> = () => {
  return (
    <div>Home it's works</div>
  )
};

export default Home;
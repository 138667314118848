import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import './App.css';
import { ROUTES } from './routes';

// pages
import { Login, Register, Home, RegisterFamily, Mission } from './pages';

const App = () => {
  return (
    <div>
      <Suspense fallback="Loading...">
          <Switch>
            <Route path={ROUTES.MISSION} component={Mission}/>
            <Route path={ROUTES.LOGIN} component={Login}/>
            <Route path={ROUTES.REGISTER} component={Register}/>
            <Route path={ROUTES.HOME} component={Home}/>
            <Route path={ROUTES.REGISTER_FAMILY} component={RegisterFamily}/>
            <Redirect to={ROUTES.LOGIN}/>
          </Switch>
      </Suspense>
    </div>
  );
};

export default App;

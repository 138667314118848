import { FC, useEffect, useRef } from "react";
import {
  Button,
  Input,
  Modal,
  FlexboxGrid,
  Icon,
  ControlLabel,
  FormGroup,
  InputGroup,
  AutoComplete,
  InputPicker,
} from "rsuite";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";
import { Table } from "rsuite";
import "../pages/Mission/add-contact-modal.css";
import { useState } from "react";
import { httpRequest } from "../common/helpers";
import { GET_ALL_CONTACTS } from "../common/constants";

const { Column, HeaderCell, Cell } = Table;

type Contacts = {
  position: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  workPlace: string;
};

type Props = {
  isOpen: boolean;
  close: () => void;
  setFamHomeSecretary: any;
  setChildAdm: any;
};

const AddContactModal: FC<Props> = ({
  isOpen,
  close,
  setFamHomeSecretary,
  setChildAdm,
}) => {
  const [sltdContact, setSltdContact] = useState<string>("");
  const [sltdRole, setSltdRole] = useState<number>(0);
  const [searchContacts, setSearchContacts] = useState<string[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchWithCriteria, setSearchWithCriteria] = useState<any[]>([]);

  useEffect(() => {
    const data = async () => {
      const res: Contacts[] = await httpRequest.get(GET_ALL_CONTACTS);
      const formatSearch = res.map((item) => item.firstName);
      const formatTable = res.map((item) => ({
        Namn: item.firstName,
        Befattning: item.position,
        Telefon: item.phone,
        Epost: item.email,
        "Företag/kommun": item.workPlace,
      }));
      setSearchContacts(formatSearch);
      setContacts(formatTable);
    };
    data();
  }, []);

  const handleSearch = () => {
    const SearchWC =
      contacts.filter((item) =>
        item.Namn.toLowerCase().includes(searchValue.toLowerCase())
      ) || [];
    setSearchWithCriteria(SearchWC);
  };

  const handleChoice = () => {
    if (+sltdRole === 0) {
      setFamHomeSecretary(sltdContact);
      setChildAdm("");
      close();
      return;
    }
    setChildAdm(sltdContact);
    setFamHomeSecretary("");
    close();
  };
  const ref = useRef<any>();

  return (
    <Modal size="lg" show={isOpen} onHide={close}>
      <Modal.Header>
        <Modal.Title style={{ display: "flex", fontSize: 25, textAlign: 'end', flexDirection: "row" }}>
          <Icon icon="book" size="4x" style={{ marginRight: "20px" }} />
          <div style={{ alignSelf: "flex-end" }}>Välj kontakt</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="auto-complete-container">
          <FlexboxGrid style={{ width: "100%" }}>
            <FlexboxGridItem className="cnt-auto-complete" colspan={12}>
              <FormGroup className="contacts">
                <ControlLabel className="contacts-label">
                  Sök kontakt:
                </ControlLabel>
                <InputGroup
                  className="input-group-cnt"
                  onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                >
                  <AutoComplete
                    data={searchContacts}
                    value={searchValue}
                    onChange={(value: string) => setSearchValue(value)}
                  />
                  <InputGroup.Button onClick={() => handleSearch()}>
                    <Icon icon="search" />
                  </InputGroup.Button>
                </InputGroup>
              </FormGroup>
            </FlexboxGridItem>
            <FlexboxGridItem className="cnt-add" colspan={12}>
              <Button appearance="primary">Ny kontakt</Button>
            </FlexboxGridItem>
          </FlexboxGrid>
        </div>
        <div style={{ border: "2px solid #8e8e93 !important" }}>
          <Table
            ref={ref}
            style={{
              padding: "10px 25px 10px 25px",
            }}
            height={420}
            data={searchWithCriteria.length > 0 ? searchWithCriteria : contacts}
            onRowClick={({ Namn }: any) => {
              setSltdContact(Namn);
            }}
          >
            <Column width={150} align="left">
              <HeaderCell>Namn</HeaderCell>
              <Cell
                dataKey="Namn"
                onDoubleClick={(e: any) => {
                  console.log("e", e.target.innerHTML);
                }}
              />
            </Column>

            <Column width={170} align="left">
              <HeaderCell>Befattning</HeaderCell>
              <Cell dataKey="Befattning" />
            </Column>

            <Column width={170} align="left">
              <HeaderCell>Telefon:</HeaderCell>
              <Cell dataKey="Telefon" />
            </Column>

            <Column width={200} align="left">
              <HeaderCell>Epost:</HeaderCell>
              <Cell dataKey="Epost" />
            </Column>

            <Column width={180} align="left">
              <HeaderCell>Företag/kommun:</HeaderCell>
              <Cell dataKey="Företag/kommun" />
            </Column>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <FlexboxGrid style={{ width: "100%" }}>
          <FlexboxGrid.Item colspan={10} className="btn-ok">
            <FormGroup className="contact-form-group">
              <ControlLabel className="footer-contacts-label">
                Vald kontakt:
              </ControlLabel>
              <Input className="input-group" value={sltdContact} />
            </FormGroup>
          </FlexboxGrid.Item>
          <FlexboxGridItem colspan={10}>
            <FormGroup className="contact-form-group">
              <ControlLabel className="label-roll">Roll:</ControlLabel>
              <InputPicker
                style={{ width: 170 }}
                value={sltdRole}
                data={roll}
                onChange={(value: string) => setSltdRole(Number(value))}
              />
            </FormGroup>
          </FlexboxGridItem>
          <FlexboxGrid.Item colspan={4} className="btn-cancel">
            <Button
              appearance="primary"
              style={{ width: "100px" }}
              onClick={handleChoice}
            >
              Klar
            </Button>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </Modal>
  );
};

export default AddContactModal;

const roll = [
  {
    label: "Familjehemssekreterare",
    value: 0,
  },
  {
    label: "Barnhandläggare",
    value: 1,
  },
];

import TextField from 'components/TextField';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, ButtonToolbar, Container, FlexboxGrid, Form, Panel, Schema, Animation } from 'rsuite';
import './Login.less';
import { ROUTES } from '../../routes';

interface LoginProps {
  
}

const { StringType } = Schema.Types;
const model = Schema.Model({
  password: StringType().isRequired('This field is required.'),
  email: StringType()
    .isEmail('Please enter a valid email address.')
    .isRequired('This field is required.')
});

const Login: React.FC<LoginProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  return (
    <Animation.Transition>
      <Container className="login__root">
        <FlexboxGrid justify="center" align="middle" style={{ height: '100%' }}>
          <FlexboxGrid.Item >
            <Panel shaded bordered style={{ width: 350, background: 'white' }}>
              <h2>
                FAWO
              </h2>
              <p>
                Familjehemsguidens matchningstjänst
              </p>
              <br/>
              <Form model={model}>
                <TextField name="email" label="E-post" />
                <TextField name="password" label="Lösenord" type="password" />
                <ButtonToolbar className="login__actions">
                  <Button appearance="primary" type="submit" loading={loading} onClick={() => {
                    setLoading(true);
                    setTimeout(() => history.push(ROUTES.REGISTER_FAMILY), 2000);
                  }}>
                    Logga in
                  </Button>
                </ButtonToolbar>
              </Form>
              <br/>
              <p>Har du inte ett konto? <Link to={ROUTES.REGISTER}>Skaffa ett här!</Link></p>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Container>
    </Animation.Transition>

  )
};

export default Login;
import { FC } from "react";
import {
  ControlLabel,
  FormGroup,
  Input,
  InputNumber,
  InputPicker,
} from "rsuite";
import FlexboxGridItem from "rsuite/lib/FlexboxGrid/FlexboxGridItem";
import { currentYear } from "../../common/helpers";
import { Gender } from "./missionTypes";
import { MultipleInput } from "../../components/MissionModal";

type Props = {
  gender: MultipleInput<Gender>[];
  name: MultipleInput<string>[];
  yearOfBirth: MultipleInput<number>[];
  description: MultipleInput<string>[];
  numberToBePlaced: number;
  setName: (value: MultipleInput<string>[]) => void;
  setGender: (value: MultipleInput<Gender>[]) => void;
  setDescription: (value: MultipleInput<string>[]) => void;
  setYearOfBirth: (value: MultipleInput<number>[]) => void;
};
// to do: handle states
const Placed: FC<Props> = ({
  name,
  gender,
  setName,
  setGender,
  yearOfBirth,
  description,
  setDescription,
  setYearOfBirth,
  numberToBePlaced,
}) => {
  const emptyArr = new Array(numberToBePlaced).fill(0);

  const onChange = (
    val: string | number | Gender,
    index: number,
    state: any[],
    setState: any
  ) => {
    let temp = [...state];

    if (temp.length > 0 && temp.length === numberToBePlaced) {
      temp[index].value = val;
    } else {
      temp[index] = { index, value: val };
      const tempKeys = Object.keys(Array.from(temp));
      temp = tempKeys.map((index) =>
        temp[+index] ? temp[+index] : { index: +index, value: "" }
      );
    }

    setState(temp);
  };

  return (
    <>
      {emptyArr.map((elem, index) => (
        <>
          <FlexboxGridItem key={index} className="flex-box-grid-item-modal">
            <FormGroup className="mr-40">
              <ControlLabel>Namn:</ControlLabel>
              <Input
                placeholder="Skriv namn"
                onChange={(val) => onChange(val, index, name, setName)}
              />
            </FormGroup>
            <FormGroup className="mr-40">
              <ControlLabel>Födelseår</ControlLabel>
              <InputNumber
                style={{ width: "224px" }}
                min={currentYear - 30}
                max={currentYear}
                step={1}
                onChange={(val) =>
                  onChange(+val, index, yearOfBirth, setYearOfBirth)
                }
                value={yearOfBirth[index]?.value || undefined}
                prefix="year"
              />
            </FormGroup>
            <FormGroup className="mr-40">
              <ControlLabel>Kön</ControlLabel>
              <InputPicker
                onChange={(value: Gender) =>
                  onChange(value, index, gender, setGender)
                }
                data={genders}
                style={{ width: 224 }}
                // defaultValue={Gender.MALE}
              />
            </FormGroup>
          </FlexboxGridItem>
          <FlexboxGridItem key={index} className="mb-24">
            <FormGroup>
              <ControlLabel>Beskrivning:</ControlLabel>
              <Input
                style={{ width: "100%" }}
                placeholder="Textområde"
                value={description[index]?.value || ""}
                onChange={(val: string, e) => {
                  onChange(val, index, description, setDescription);
                }}
                componentClass="textarea"
                rows={3}
              />
            </FormGroup>
          </FlexboxGridItem>
        </>
      ))}
    </>
  );
};

export default Placed;

const genders = [
  {
    label: "Pojke",
    value: Gender.MALE,
  },
  {
    label: "Flicka",
    value: Gender.FEMALE,
  },
  {
    label: "icke binär",
    value: Gender.NON_BINARY,
  },
];
